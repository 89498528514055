.card{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--bg-color);
    background-clip: border-box;
    border-radius: 15px;
    padding: 1rem;
}

.subcontainer1{
    display: flex;
    gap: 0.3rem;
    align-items: center;
    justify-content: space-between;
    padding-block: 1rem;
    flex-wrap: wrap;
}



