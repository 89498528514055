/* Shared Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .printable {
    width: 100%;
    margin: 0 auto;
    padding: 15px;
    background-color: #fff;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #f3f3f3;
  }
  
  .text-center {
    text-align: center;
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .logo {
    height: 50px;
    width: 50px;
    background: url('../../../assest/logo.png') no-repeat center;
    background-size: contain;
  }
  
  #top .info h2 {
    margin: 0;
    font-size: 1.2em;
  }
  
  #top {
    margin-bottom: 10px;
  }
  
  #bot {
    margin-top: 10px;
  }
  
  #bot td, #bot th {
    font-size: 0.8rem;
  }
  
  /* Normal View Styles */
  @media screen {
    .printable {
      padding: 20px;
      background: #f9f9f9;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  
    table {
      border: 1px solid #ddd;
      border-radius: 5px;
      overflow: hidden;
    }
  
    th, td {
      font-size: 1em;
      color: #333;
    }
  
    .text-center {
      text-align: center;
    }
  
    .font-bold {
      font-weight: bold;
    }
  }
  
  /* Print View Styles */
  @media print {
    body {
      font-family: Arial, sans-serif;
    }
  
    .printable {
      width: 100%; /* Adjust this for smaller paper (e.g., receipts) */
      margin: 0 auto;
      background: #fff;
      padding: 10px;
      box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
    }
  
    th, td {
      padding: 8px;
      text-align: left;
      font-size: 0.9em;
    }
  
    th {
      background-color: #f3f3f3;
    }
  
    .text-center {
      text-align: center;
    }
  
    .font-bold {
      font-weight: bold;
    }
  
    .logo {
      height: 50px;
      width: 50px;
      background: url('../../../assest/logo.png') no-repeat center;
      background-size: contain;
    }
  
    #top .info h2 {
      margin: 0;
      font-size: 1.2em;
    }
  
    #top {
      margin-bottom: 10px;
    }
  
    #bot {
      margin-top: 10px;
    }
  
    #bot td, #bot th {
      font-size: 0.8em;
    }
  }
  