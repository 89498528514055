
.advertisement_card {
    margin: 2rem;
    width: 300px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s;
    /* margin: 16px; */
}

.advertisement_card:hover {
    transform: scale(1.05);
}

.advertisement_image {
    width: 100%;
    height: 12rem;
    object-fit: cover;
}

.ad_content {
    padding: 16px;
}

.ad_title {
    font-size: 1.5em;
    margin: 0 0 8px;
    color: #333;
    white-space: nowrap;        
    overflow: hidden;              
    text-overflow: ellipsis;       
    max-width: 100%; 
}

.ad_product, .ad_date {
    font-size: 1em;
    margin: 4px 0;
    color: #555;
}
