.custom-location-button {
    margin: 10px;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
  
  .custom-location-button:hover {
    background-color: #f0f0f0;
  }
  
  .selected-location-info {
    margin-top: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  