.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 2px;
}

.tooltip-text {
  /* max-width: 250px; */
  font-size: 1rem;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.685);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  overflow-wrap: break-word;
  word-break: break-all;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.589);
}

/* Arrow styles */
.tooltip-text::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

/* Tooltip positions */
.tooltip-top .tooltip-text {
  bottom: 25%;
  left: 50%;
  margin-left: -10px;
  text-transform: capitalize;
}

.tooltip-top .tooltip-text::after {
  bottom: -10px;
  left: 50%;
  border-color: black transparent transparent transparent;
  margin-left: -5px; /* Center the arrow */
}

.tooltip-right .tooltip-text {
  top: -5px;
  left: 105%;
}

.tooltip-right .tooltip-text::after {
  top: 50%;
  left: -10px;
  border-color: transparent black transparent transparent;
  margin-top: -5px;
}

.tooltip-bottom .tooltip-text {
  top: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-bottom .tooltip-text::after {
  top: -10px;
  left: 50%;
  border-color: transparent transparent black transparent;
  margin-left: -5px;
}

.tooltip-left .tooltip-text {
  top: -5px;
  right: 105%;
}

.tooltip-left .tooltip-text::after {
  top: 50%;
  right: -10px;
  border-color: transparent transparent transparent black;
  margin-top: -5px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
