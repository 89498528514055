.dashboard {
  background-color: var(--forground-bg-color);
}
.main {
  display: flex;
}
.content {
  /* padding: 2rem; */
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  margin-bottom: 2rem;
}
/* .sidebar{    
    width: fit-content;
    min-height: 100vh;
    background: var(--bg-color);
    transition: transform .3s, border-radius .3s;
    padding: 1.5rem 0;
    box-shadow: 0 1px 15px #0000000a, 0 1px 6px #0000000a;
    border-top-right-radius: 1rem;
    transition: width 2s  1s;
}
.sidebarShow{
    display: block;    
}
.sidebarHide{
    display: none;
} */

.sidebar {
  width: auto;
  /* min-width: 6.2rem; */
  min-height: 100vh;
  background: var(--bg-color);
  transition: transform 0.6s, border-radius 0.3s, opacity 0.3s;
  box-shadow: 0 1px 15px #0000000a, 0 1px 6px #0000000a;
  border-top-right-radius: 1rem;
  position: relative;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  display: flex;
}

.sidebarShow {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.sidebarHide {
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  width: 0;
}

.subMenu {
  padding-inline: 2rem;
  width: 15rem;
}
.content::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.content::-webkit-scrollbar-thumb {
  background: var(--color--2);
  border-radius: 10px;
}

/* Dashboard @start */
.dashboardProgress {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;

  margin-bottom: 1rem;
}
/* Dashboard @end */
