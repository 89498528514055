.messageBox {
  width: 70%;
  padding: 1vmax;
  margin: 1vmax;
  border-radius: 0.5vmax;
  -webkit-border-radius: 0.5vmax;
  -moz-border-radius: 0.5vmax;
  -ms-border-radius: 0.5vmax;
  -o-border-radius: 0.5vmax;
  display: inline-block;
  clear: both;
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  font-size: 1rem;
}

.message_right {
  float: right;
  background-color: #005c4b;
  color: white;
  width: auto;
}
.message_left {
  float: left;
  background-color: #202c33;
  color: white;
  width: auto;
}

.message_message-date {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 12px !important;
  font-family: cursive;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.react-scroll-to-bottom--css-zkgbw-1tj0vk3 {
  background-color: #e34e4e !important;
  border-radius: 50% !important;
  border-width: 0;
  bottom: 15px !important;
  cursor: pointer;
  height: 30px !important;
  outline: 0;
  position: absolute;
  right: 40px !important;
  width: 30px !important;
}

/* file uploading style start */

.file__modal__container {
  width: 76%;
  height: 78vh;
  color: white;
  background-color: #202c33;
  padding: 2rem;
  margin: 0.5rem;
}

.file__name__img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}

.file__modal__container > i {
  float: right;
  cursor: pointer;
}

.image__display {
  overflow: auto;
  height: 50vh;
}

.multiple__images__add {
  width: 3rem;
  height: 3rem;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 2rem 0rem;
  cursor: pointer;
}

.images__container {
  position: relative;
}

.images__container:hover {
  cursor: pointer;
}

.fImage:hover {
  opacity: 0.2;
}

.image__active {
  opacity: 0.2;
}

.deleteImages {
  font-size: 3rem;
  position: absolute;
  top: 40%;
  left: 45%;
}

.message__image {
  width: 30vh;
  height: 30vh;
  object-fit: contain;
  background-color: #f0f0f0;
}

/* file uploading style end */
