.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem;
  border-radius: 20px;
  gap: 2rem;
  /* box-shadow: 0 1px 15px #0000000a, 0 1px 6px #00000094; */
  border: 1px solid var(--border-color2);
  flex: 1;
}

.circle {
  position: relative;
  width: 10rem;
  height: 10rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressBorder {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(var(--color--11, #4caf50) calc(var(--progress, 0)* 1%), #ccc calc(var(--progress, 0)* 1%));
  box-shadow: 0 1px 15px #0000000a, 0 1px 6px #00000094;
  border: 1px solid #ccc;
  z-index: 0;
}

.coveredText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: conic-gradient(
    white calc(var(--progress, 0) * 1%),
    transparent calc(var(--progress, 0) * 1%)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.125rem;
  font-weight: bold;
  z-index: 2;
}

.remainingText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 1.125rem;
  font-weight: bold;
  z-index: 1;
}

.cardText {
  font-size: 1rem;
  text-align: center; 
}
