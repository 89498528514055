
.content_list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}

/* width */
.container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  background: var(--color--2);
  border-radius: 10px;
}

@media only screen and (max-width: 500px) {
  .container {
    width: 100%;
  }
}
