.kpiCard {
  background: var(--bg-color);
  border-radius: 5px;
  box-shadow: 0 0px 15px 0px rgba(0,0,0,0.2);
  /* flex: 1 1; */
  padding: 1rem;
}
.wrapper{
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
}
.pIcon{
font-size: 2rem;
    color: var(--color--11);
    box-shadow: 0 1px 15px #0000000a, 0 1px 6px #00000094;
    padding: .5rem;
    border-radius: 5px;
}
.pLabel{
  font-size:1rem;
}
