@keyframes blink {
  0% {
    box-shadow: 0 0 0px rgba(255, 0, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0px rgba(255, 0, 0, 0.5);
  }
}

.blink-shadow {
  animation: blink 1s infinite;
}
