.discount-tag {
    position: absolute;
    top: -34px;
    left: 7px;
    background-color: #ff0000;
    color: white;
    width: 100px;
    height: 50px;
    transform: rotate(-22deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}
  
  .discount-tag::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -12px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    box-shadow: inset 0 0 0 4px #ff0000;
    transform: translateY(-50%);
  }
  